export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  EcomPlatformCartAndCheckout: 'specs.stores.EcomPlatformCartAndCheckout',
  AddPickupPointsToCart: 'specs.stores.AddPickupPointsToCart',
  SecureCheckoutVelo: 'specs.stores.ShowHideSecureCheckoutVelo',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  fixEmailNotifierInCart: 'specs.stores.fixEmailNotifierInCart',
  UseGlobalCssForOneColumnCart: 'specs.stores.UseGlobalCssForOneColumnCart',
  HideCountryPickerInCart: 'specs.stores.HideCountryPickerInCart',
  ShouldPreloadPaymentMethods: 'specs.stores.ShouldPreloadPaymentMethods',
  GetCartWithConsentRequiredPaymentPolicyOnLineItem: 'specs.stores.GetCartWithConsentRequiredPaymentPolicyOnLineItem',
  SupportCardTokenizationOnCartAndCheckout: 'specs.stores.SupportCardTokenizationOnCartAndCheckout',
} as const;
